<template>
  <div class="jdtj">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div id="dataList" slot="scrollList">
        <van-sticky>
          <search :search-out="searchOut" />
        </van-sticky>
        <div class="jdtj-banner" :style="{'background-image': 'url( '+ types.bgImg +')'}">
          <div class="img-box">
            <img :src="types.banner" alt="">
          </div>
        </div>
        <div style="background:rgb(236,236,236);height:10px;" />
        <van-tabs v-model="active" :border="false" title-active-color="#d6ad51" color="#d6ad51" @click="changes">
          <van-tab v-for="(item,idx) in types.types" :key="idx" :title="item.typeName">
            <div style="border-bottom:1px solid rgb(198,198,198);" />
          </van-tab>
        </van-tabs>
        <van-tabs v-model="eleactive" title-active-color="#d6ad51" color="#d6ad51" @click="changesl">
          <van-tab v-for="ele in typetwos" :key="ele.typeName" :name="ele.typeId" :title="ele.typeName" />
        </van-tabs>

        <div class="jdshoplist">
          <dl v-for="list in typeList" :key="list.typeId" @click="godetail(list)">
            <dt>
              <imgLoading :src="list.goodCover" />
            </dt>
            <dd>
              <div class="title">{{ list.goodName }}</div>
              <div class="price"><span class="price1">会员价:￥{{ list.goodVipPrice }}</span><span
                style="text-decoration: line-through"
              >{{ list.priceName }}:{{ list.goodPrice }}</span></div>
            </dd>
          </dl>
        </div>
      </div>
    </mescroll>

  </div>
</template>

<script>
/* eslint-disable eqeqeq */
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue'
import { Sticky, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(Sticky)
import imgLoading from '@/component/imgLoading'
import { getpreferential } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'
import search from '@/component/head/search'

export default {
  name: 'JdSpecialOffer',
  // import引入的组件需要注入到对象中才能使用
  components: {
    imgLoading,
    mescroll,
    search
  },
  data() {
    // 这里存放数据
    return {
      searchOut: {
        bgColor: 'rgb(84,76,70)', // 设置导航背景颜色
        titleData: '', // 设置标题 title 名字
        urlImg: '', // 点击返回图片 可以设置
        placeholder: '搜索内容', // 搜索框 input placeholder 值
        isShowReturn: true, // 点击返回图片 显隐
        callbackGo: this.callbackGo // 返回回调函数
      },
      mescrollDown: {
        use: true,
        bottom: '0'
      },
      mescrollUp: {
        use: true,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      parmas: {
        typeId: 0,
        channelId: 3,
        page: 1,
        pageSize: 10
      },
      types: [], // 一级分类数组
      typetwos: [],
      typeList: [],
      eleactive: 0,
      active: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  },
  // 方法集合
  methods: {
    // 一级分类点击typeId取对应二级分类的第一个每次点击需要给当前状态清空
    changes(name) {
      const that = this
      that.typeList = []
      that.eleactive = 0
      that.parmas.page = 0
      that.mescrollUp.page.num = 0
      that.parmas.typeId = that.types.types[name].typeList[0].typeId
      getpreferential(that.parmas).then(res => {
        that.typetwos = that.types.types[name].typeList
        that.typeList = res.data.list
      })
    },
    // 二级分类每次点击需要给当前状态清空
    changesl(name, title) {
      const that = this
      that.typeList = []
      this.parmas.typeId = name
      that.parmas.page = 0
      that.mescrollUp.page.num = 0
      getpreferential(this.parmas).then(res => {
        that.typeList = res.data.list
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.typeList = []
          // 把请求到的数据添加到列表
          this.typeList = this.typeList.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            let number = 0
            if (arr.length > 0) {
              number = 11
            }
            mescroll.endSuccess(number)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const that = this
      that.parmas.page = pageNum
      that.parmas.pageSize = pageSize
      getpreferential(that.parmas).then(res => {
        if (Number(res.code) === 200) {
          that.types = res.data
          that.typetwos = this.types.types[0].typeList
          successCallback(res.data.list)
        }
      })
    },
    // 跳转详情
    godetail(item) {
      const obj = {
        product_type: '',
        goodId: '',
        skuId: ''
      }
      obj.goodId = item.goodId
      obj.skuId = item.productSkuId
      Object.keys(item).forEach((key, index) => {
        // 将跳转详情的类型字段定义为product_type
        if (key == 'redirectType' || key == 'product_type') {
          obj.product_type = item[key]
        }
      })
      this.$store.dispatch('godetail', obj)
    },
    // 点击返回图标
    callbackGo() {
      this.$router.go(-1)
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
* {
  text-align: left;
}

//@import url(); 引入公共css类
.jdtj {
  width: 100%;
  background: #cccccc;

  .jdtj-banner {
    width: 100%;
    height: 180px;
    background-repeat: no-repeat; //不重复
    background-size: 100% 100%;

    .img-box {
      width: 94%;
      margin-left: 3%;
      height: 180px;
      padding-top: 20px;

      img {
        width: 100%;
        height: 160px;
      }
    }
  }

  .jdshoplist {
    width: 100%;
    // display: flex;
    // justify-content: space-around;
    // flex-wrap: wrap;
    background: #f6f6f6;

    dl {
      margin-top: 10px;
      width: 43%;
      height: 250px;
      background: #ffffff;
      border-radius: 10px;
      display: inline-block;
      margin-left: 5%;

      dt {
        width: 90%;
        height: 160px;
        margin: 0 auto;
        margin-top: 5px;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      dd {
        width: 90%;
        margin: 0 auto;

        .title {
          width: 100%;
          height: 38px;
          line-height: 19px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 12px;
          word-break: break-all;
          text-align: left;
        }

        .price {
          margin-top: 5px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          .price1 {
            color: #d6ad51;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
